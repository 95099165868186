import { createSlice } from "@reduxjs/toolkit";

// Define the initial state with formData and step
const initialState = {
  formData: {
    fullname: "", // Changed from name to fullname
    dob: "",
    aadharCard: "",
    whatsappNumber: "",
    tshirtSize: "",
    city: "",
    state: "",
    visitDay: "",
    emergencyContact: "",
  },
  step: 1,
  error: null, // To store validation errors
};

const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    // Action to update form data dynamically
    updateFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },

    // Action to move to the next step
    nextStep: (state) => {
      if (state.step < 4) {
        state.step += 1;
        state.error = null; // Reset any errors when moving to the next step
      }
    },

    // Action to move to the previous step
    prevStep: (state) => {
      if (state.step > 1) {
        state.step -= 1;
        state.error = null; // Reset any errors when moving back
      }
    },

    // Action to reset the form data and step
    resetForm: (state) => {
      state.step = 1;
      state.formData = initialState.formData; // Reset form data
      state.error = null; // Clear any validation errors
    },

    // Action to validate the current step before proceeding
    validateStep: (state) => {
      switch (state.step) {
        case 1:
          // Validation for merged "Personal & Contact Info"
          if (
            !state.formData.fullname ||
            !state.formData.dob ||
            !state.formData.aadharCard ||
            !state.formData.whatsappNumber ||
            !state.formData.tshirtSize
          ) {
            state.error =
              "Please fill out all fields in Personal & Contact Info.";
          } else {
            state.error = null;
          }
          break;
        case 2:
          if (
            !state.formData.city ||
            !state.formData.state ||
            !state.formData.visitDay
          ) {
            state.error = "Please fill out all fields in Location Info.";
          } else {
            state.error = null;
          }
          break;
        case 3:
          if (!state.formData.emergencyContact) {
            state.error = "Please fill out the Emergency Contact field.";
          } else {
            state.error = null;
          }
          break;
        default:
          state.error = null;
          break;
      }
    },
  },
});

// Export the actions to be used in the form component
export const { updateFormData, nextStep, prevStep, resetForm, validateStep } =
  formSlice.actions;

// Export the reducer to be used in the store
export default formSlice.reducer;
