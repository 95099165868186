import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import Register from './pages/Register';
import Success from './pages/Success';

import Test from './pages/Test';

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        {/* Make LandingPage the home page */}
        <Route exact path="/" element={<LandingPage />} />
        <Route path="/register" element={<Register />} />
        <Route path="/success" element={<Success />} />

        <Route path="/test" element={<Test />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
