import React from "react";

const Loader = () => (
  <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-40 backdrop-blur-sm p-8 rounded-lg">
    <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
      <div className="loader-gradient h-12 w-12 rounded-full border-t-4 border-b-4 border-transparent animate-spin"></div>
      <span className="text-gray-700 text-lg font-semibold mt-5">Loading...</span>
    </div>
    <style jsx>{`
      .loader-gradient {
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: #3b82f6; /* Change this color if needed */
        background-image: conic-gradient(
          from 0deg,
          #3b82f6,
          #60a5fa,
          #3b82f6,
          #3b82f6
        );
      }
    `}</style>
  </div>
);

export default Loader;
