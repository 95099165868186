import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Joi from "joi";
import FormFields from "./registration/FormFields";
import ImageCapture from "./registration/ImageCapture";
import Loader from "./Loader";
import { submitRegistration } from "../services/apiService";
import axios from "axios";

// Joi schema for form validation
const registrationSchema = Joi.object({
  fullName: Joi.string().min(3).max(50).required().messages({
    "string.empty": "Full name is required.",
    "string.min": "Full name must be at least 3 characters.",
    "string.max": "Full name can't exceed 50 characters.",
  }),
  dateOfBirth: Joi.date().iso().required().messages({
    "date.base": "Invalid date format.",
    "date.iso": "Date of Birth must be in ISO format.",
  }),
  aadharCardNumber: Joi.string()
    .pattern(/^\d{12}$/)
    .required()
    .messages({
      "string.empty": "Aadhaar Card Number is required.",
      "string.pattern.base": "Aadhaar Card Number must be exactly 12 digits.",
    }),
  whatsappNumber: Joi.string()
    .pattern(/^\d{10}$/)
    .required()
    .messages({
      "string.empty": "WhatsApp Number is required.",
      "string.pattern.base": "WhatsApp Number must be exactly 10 digits.",
    }),
  tshirtSize: Joi.string()
    .valid("S", "M", "L", "XL", "XXL")
    .required()
    .messages({
      "any.only": "T-shirt Size must be one of S, M, L, XL, XXL.",
      "string.empty": "T-shirt Size is required.",
    }),
  city: Joi.string().required().messages({
    "string.empty": "City is required.",
  }),
  state: Joi.string().required().messages({
    "string.empty": "State is required.",
  }),
  visitDay: Joi.number().min(1).max(60).required().messages({
    "number.base": "Visit Day must be a number.",
    "number.min": "Visit Day must be at least 1.",
    "number.max": "Visit Day can't exceed 60.",
  }),
  emergencyContactNumber: Joi.string()
    .pattern(/^\d{10}$/)
    .required()
    .messages({
      "string.empty": "Emergency Contact Number is required.",
      "string.pattern.base":
        "Emergency Contact Number must be exactly 10 digits.",
    }),
});

const RegistrationForm = () => {
  const navigate = useNavigate();

  // Individual states for each field
  const [fullName, setFullName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [aadharCardNumber, setAadharCardNumber] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [tshirtSize, setTshirtSize] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [visitDay, setVisitDay] = useState("");
  const [emergencyContactNumber, setEmergencyContactNumber] = useState("");

  const [capturedImage, setCapturedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imageVerified, setImageVerified] = useState(false);

  // Real-time field validation using Joi schema
  const validateField = (field, value) => {
    const { error } = registrationSchema.extract(field).validate(value);
    if (error) {
      toast.error(error.details[0].message);
      return false;
    }
    return true;
  };

  // // Real-time validation for each field
  // useEffect(() => {
  //   if (fullName) validateField("fullName", fullName);
  // }, [fullName]);

  // useEffect(() => {
  //   if (dateOfBirth) validateField("dateOfBirth", dateOfBirth);
  // }, [dateOfBirth]);

  // useEffect(() => {
  //   if (aadharCardNumber) validateField("aadharCardNumber", aadharCardNumber);
  // }, [aadharCardNumber]);

  // useEffect(() => {
  //   if (whatsappNumber) validateField("whatsappNumber", whatsappNumber);
  // }, [whatsappNumber]);

  // useEffect(() => {
  //   if (tshirtSize) validateField("tshirtSize", tshirtSize);
  // }, [tshirtSize]);

  // useEffect(() => {
  //   if (city) validateField("city", city);
  // }, [city]);

  // useEffect(() => {
  //   if (state) validateField("state", state);
  // }, [state]);

  // useEffect(() => {
  //   if (visitDay) validateField("visitDay", visitDay);
  // }, [visitDay]);

  // useEffect(() => {
  //   if (emergencyContactNumber)
  //     validateField("emergencyContactNumber", emergencyContactNumber);
  // }, [emergencyContactNumber]);

  // Handle image capture
  const handleImageCapture = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCapturedImage(file);
    }
  };

  // Verify Image as Human
  const verifyHumanImage = async () => {
    if (!capturedImage) {
      toast.error("Please upload an image for verification.");
      return;
    }

    const formDataForImage = new FormData();
    formDataForImage.append("image", capturedImage);

    setLoading(true);
    try {
      const response = await axios.post(
        "https://img.hindujodoyatra.org/detect-real-human",
        formDataForImage,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.humanDetected) {
        console.log("Image verified successfully");
        toast.success("✅ Profile image verification done!", {
          style: {
            border: "1px solid #4CAF50",
            padding: "16px",
            color: "#4CAF50",
            fontWeight: "bold",
          },
          iconTheme: {
            primary: "#4CAF50",
            secondary: "#E8F5E9",
          },
        });
        setImageVerified(true);
      } else {
        console.log("Image verification failed");
        toast.error("❌ Image verification failed. Please try again!", {
          style: {
            border: "1px solid #ff4d4f",
            padding: "16px",
            color: "#ff4d4f",
            fontWeight: "bold",
          },
          iconTheme: {
            primary: "#ff4d4f",
            secondary: "#FFFAEE",
          },
        });
        setImageVerified(false);
      }
    } catch (error) {
      console.log("Error during image verification:", error);
      toast.error("Error verifying image. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Convert image to base64
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  // Submit form with captured image after validation
  const handleSubmit = async () => {
    const validationResult = registrationSchema.validate(
      {
        fullName,
        dateOfBirth,
        aadharCardNumber,
        whatsappNumber,
        tshirtSize,
        city,
        state,
        visitDay,
        emergencyContactNumber,
      },
      { abortEarly: false }
    );

    if (validationResult.error) {
      validationResult.error.details.forEach((detail) =>
        toast.error(detail.message)
      );
      return;
    }

    if (!imageVerified) {
      toast.error("Please verify the image first.");
      return;
    }

    const registrationPayload = {
      fullName,
      dateOfBirth,
      aadharCardNumber,
      whatsappNumber,
      tshirtSize,
      city,
      state,
      visitDay,
      emergencyContactNumber,
      capturedImage: capturedImage
        ? await convertToBase64(capturedImage)
        : null,
    };

    console.log("Registration Payload:", registrationPayload);

    setLoading(true);
    try {
      const data = await submitRegistration(registrationPayload);

      if (data.success) {
        toast.success("Registration successful!");
        navigate("/success", { state: { registrationPayload, capturedImage } });
      } else {
        toast.error(data.message || "Registration failed.");
      }
    } catch (error) {
      toast.error("Error submitting the form. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center bg-orange-200 pt-24 pb-4 min-h-screen">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-2xl font-bold mb-4">Registration Form</h2>

        {loading ? (
          <Loader />
        ) : !imageVerified ? (
          <>
            <ImageCapture
              handleImageCapture={handleImageCapture}
              capturedImage={capturedImage}
            />
            <button
              type="button"
              className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 transition duration-300 mt-4"
              onClick={verifyHumanImage}
            >
              Submit Image
            </button>
          </>
        ) : (
          <form onSubmit={(e) => e.preventDefault()} className="space-y-4">
            <FormFields
              fullName={fullName}
              setFullName={setFullName}
              dateOfBirth={dateOfBirth}
              setDateOfBirth={setDateOfBirth}
              aadharCardNumber={aadharCardNumber}
              setAadharCardNumber={setAadharCardNumber}
              whatsappNumber={whatsappNumber}
              setWhatsappNumber={setWhatsappNumber}
              tshirtSize={tshirtSize}
              setTshirtSize={setTshirtSize}
              city={city}
              setCity={setCity}
              state={state}
              setState={setState}
              visitDay={visitDay}
              setVisitDay={setVisitDay}
              emergencyContactNumber={emergencyContactNumber}
              setEmergencyContactNumber={setEmergencyContactNumber}
            />
            <button
              type="button"
              className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 transition duration-300"
              onClick={handleSubmit}
            >
              Submit Form
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default RegistrationForm;
