import React from "react";
import { useNavigate } from "react-router-dom";
import banner from "../assets/images/adinath-logo.png";
import tshirt from "../assets/images/t-shirt.jpg";
import Layout from "../components/Layout"; // Import the Layout component

const LandingPage = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      {/* Banner Section */}
      <div className="flex flex-col items-center justify-center pt-32">
        <div className="mb-8 animate-fadeIn">
          <img
            src={banner}
            alt="Hindu Jodo Yatra"
            className="w-full max-w-lg object-cover shadow-lg rounded-lg"
          />
        </div>

        {/* Event Details Section */}
        <div className="text-center px-4 lg:px-32 mb-8 animate-slideUp">
          <h2 className="text-3xl font-bold mb-4">Hindu Jodo Yatra 2024</h2>
          <p className="text-lg text-gray-700 mb-6">
            The <span className="font-semibold">Hindu Jodo Yatra 2024</span> is
            a spiritual and cultural journey aimed at uniting Hindus across the
            country.
          </p>
          <p className="text-lg text-gray-700 mb-6">
            From <span className="font-semibold">Kashmir</span>'s Lal Chowk to{" "}
            <span className="font-semibold">Kanyakumari</span>, the yatra
            promotes peace, harmony, and the core values of Hinduism.
          </p>

          {/* <p className="text-lg text-gray-700 mb-6">
            <span className="font-semibold">कश्मीर</span> के लाल चौक से{" "}
            <span className="font-semibold">कन्याकुमारी</span> तक, यात्रा शांति,
            सद्भाव और हिन्दू धर्म के मूल मूल्यों को बढ़ावा देती है।
          </p> */}
        </div>

        {/* Register Button */}
        <button
          onClick={() => navigate("/register")}
          className="bg-red-500 text-white font-semibold py-2 px-6 rounded-full hover:bg-red-600 transition duration-300 transform hover:scale-105 animate-bounce"
        >
          Register Now
        </button>

        {/* Additional Content after Register Button */}
        <div className="text-center px-4 lg:px-32 mt-12">
          <h3 className="text-2xl font-semibold mb-4">Yatra Highlights</h3>
          <p className="text-lg text-gray-700 mb-6">
            The yatra will cover significant spiritual locations across India,
            including sacred temples, ashrams, and pilgrimage sites.
          </p>
          <ul className="list-disc text-left text-gray-700 mb-6 space-y-2">
            <li>Special prayers and rituals at key locations.</li>
            <li>Cultural programs showcasing India's diversity.</li>
            <li>Discussions and spiritual discourses on Hindu philosophy.</li>
            <li>Community service initiatives promoting peace and unity.</li>
          </ul>
        </div>

        <div className="mb-8 animate-fadeIn">
          <img
            src={tshirt}
            alt="Hindu Jodo Yatra T-shirt"
            className="w-full max-w-lg object-cover shadow-lg rounded-lg"
          />
        </div>

      </div>
    </Layout>
  );
};

export default LandingPage;
