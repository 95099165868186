import './App.css';
import React from 'react';
import AppRouter from './AppRouter';

const App = () => {
  return <AppRouter />;
};

export default App;

