// src/components/registration/ImageCapture.js

import React from "react";

const ImageCapture = ({ handleImageCapture, capturedImage }) => {
  return (
    <div className="mt-4">
      <label htmlFor="captureImage" className="block text-gray-700 mb-2">
        Upload ID Card Image for Registration
      </label>
      <input
        type="file"
        id="captureImage"
        accept="image/*"
        capture="user" // Opens camera on mobile devices by default
        onChange={handleImageCapture}
        className="w-full px-4 py-2 border rounded-lg focus:outline-none"
      />
      {capturedImage && (
        <div className="mt-4">
          <h3>Image Preview:</h3>
          <img
            src={URL.createObjectURL(capturedImage)}
            alt="ID Card Preview"
            className="rounded border"
            style={{ width: "100px", height: "100px" }}
          />
        </div>
      )}
    </div>
  );
};

export default ImageCapture;
