import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import Layout from "../components/Layout"; // Import Layout component
import logo from "../assets/images/adinath-logo.png"; // Import logo image
import cardTemplate from "../assets/images/card-template.png"; // Add your card template background image

const Success = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const registrationPayload = location.state?.registrationPayload;
  const capturedImage =
    registrationPayload?.capturedImage ||
    "https://plus.unsplash.com/premium_photo-1682096259050-361e2989706d"; // Use placeholder if no image

  const cardRef = useRef(); // Reference for the card to capture

  if (!registrationPayload) {
    return <Layout><p className="m-36 text-center">No data found! Please register first.</p></Layout>;
  }

  // Generate pass validity dates
  const today = new Date();
  const validityEnd = new Date();
  validityEnd.setFullYear(validityEnd.getFullYear() + 1); // 1-year validity

  // Function to generate a random 4-digit number
  const generateRandomNumber = () => {
    return Math.floor(1000 + Math.random() * 9000); // Generates a 4-digit number
  };

  // Handle download functionality with high resolution
  const handleDownload = () => {
    const cardElement = cardRef.current;
    const randomNumber = generateRandomNumber();
    const userName = registrationPayload.fullName.replace(/\s+/g, "_"); // Replace spaces with underscores for the user's name
    // Capture the card content with a higher scaling factor for better quality
    html2canvas(cardElement, { scale: 3, useCORS: true }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png"); // Capture high-quality image
      const pdf = new jsPDF("portrait", "pt", [288, 432]); // ID card size (4x6 inches)

      // Add high-resolution image to PDF
      pdf.addImage(imgData, "PNG", 0, 0, 288, 432); // Use high-quality PNG image

      pdf.save(`${userName}_${randomNumber}_Parchaye_Patr_Card.pdf`);
    });
  };

  return (
    <Layout>
      <div className="flex flex-col justify-center items-center min-h-screen bg-gray-100 p-4">
        {/* Card Preview */}
        <div
          className="relative bg-white shadow-lg rounded-lg"
          style={{ width: "4in", height: "6in", position: "relative" }} // Set card size to 4x6 inches
          ref={cardRef}
        >
          {/* Card background */}
          <img
            src={cardTemplate}
            alt="Card Template"
            className="absolute inset-0 w-full h-full object-cover"
          />

          {/* Captured Image */}
          {capturedImage && (
            <div
              className="absolute"
              style={{
                top: "177px", // Adjust position according to the template
                left: "38px",
                width: "110px",
                height: "122px",
                border: "2px solid black",
                backgroundColor: "white",
              }}
            >
              <img
                src={capturedImage}
                alt="Captured"
                className="w-full h-full object-cover"
              />
            </div>
          )}

          {/* Card Information */}
          <div
            className="absolute text-left text-sm"
            style={{
              top: "313px",
              left: "40px",
              lineHeight: "1.5",
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              gap: "8px",
            }} // Adjust position, line-height, and grid layout for alignment
          >
            <p style={{ marginBottom: "9px" }}>
              <strong>Name: </strong>
            </p>
            <p style={{ marginBottom: "9px" }}>{registrationPayload.fullName}</p>

            <p style={{ marginBottom: "9px" }}>
              <strong>Date of Birth: </strong>
            </p>
            <p style={{ marginBottom: "9px" }}>{registrationPayload.dateOfBirth}</p>

            <p style={{ marginBottom: "9px" }}>
              <strong>Aadhar Card No: </strong>
            </p>
            <p style={{ marginBottom: "9px" }}>{registrationPayload.aadharCardNumber}</p>

            <p style={{ marginBottom: "9px" }}>
              <strong>WhatsApp No: </strong>
            </p>
            <p style={{ marginBottom: "9px" }}>{registrationPayload.whatsappNumber}</p>

            <p style={{ marginBottom: "9px" }}>
              <strong>City: </strong>
            </p>
            <p style={{ marginBottom: "9px" }}>{registrationPayload.city}</p>

            <p style={{ marginBottom: "9px" }}>
              <strong>State: </strong>
            </p>
            <p style={{ marginBottom: "9px" }}>{registrationPayload.state}</p>
          </div>
        </div>

        {/* Buttons for Actions */}
        <div className="mt-8 flex space-x-4">
          <button
            onClick={() => navigate(-1)}
            className="bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-600 transition duration-300"
          >
            Go Back
          </button>

          <button
            onClick={handleDownload}
            className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 transition duration-300"
          >
            Download Card
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default Success;
