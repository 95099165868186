import React from 'react';
import leftLogo from '../assets/images/hindu-jodo-logo.png';  // Replace with the actual path
import rightLogo from '../assets/images/adinath-logo.png';  // Replace with the actual path

const Header = () => {
  return (
    <header className="w-full bg-orange-300 shadow-md py-4 fixed top-0 z-10">
      <div className="container mx-auto flex justify-between items-center px-4">
        {/* Left logo */}
        <img src={leftLogo} alt="Hindu Jodo Logo" className="w-16 h-16 object-contain" />

        {/* Center text */}
        <div className="text-center text-orange-700 font-semibold">
          <h1 className="text-lg font-bold">हिंदू जोडो यात्रा- 2025</h1>
          {/* <h2 className="text-sm">भव्य हिंदू जोड़ो यात्रा</h2> */}
          
          <p className="text-xs">कश्मीर के लाल चौक से कन्याकुमारी तक</p>
          <p className="text-sm">देश, देव, धर्म और संस्कृति की रक्षा के लिए आदिनाथ संप्रदायिका बढता कदम</p>
        </div>

        {/* Right logo */}
        <img src={rightLogo} alt="Adinath Logo" className="w-16 h-16 object-contain" />
      </div>

      {/* Additional subtitle or tagline (optional) */}
      {/* <div className="text-center mt-2 text-xs text-orange-700  font-semibold">
        <p>देश, देव, धर्म और संस्कृति की रक्षा के लिए आदिनाथ संप्रदायिका बढता कदम</p>
      </div> */}
    </header>
  );
};

export default Header;
