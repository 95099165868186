import React from 'react';
import LabeledInput from '../LabeledInput';

const FormFields = ({
  fullName,
  setFullName,
  dateOfBirth,
  setDateOfBirth,
  aadharCardNumber,
  setAadharCardNumber,
  whatsappNumber,
  setWhatsappNumber,
  tshirtSize,
  setTshirtSize,
  city,
  setCity,
  state,
  setState,
  visitDay,
  setVisitDay,
  emergencyContactNumber,
  setEmergencyContactNumber,
}) => {
  return (
    <>
      <LabeledInput
        label="Full Name"
        name="fullName"
        type="text"
        placeholder="Full Name"
        onChange={(e) => setFullName(e.target.value)}
        value={fullName}
        required
      />
      <LabeledInput
        label="Date of Birth"
        name="dateOfBirth"
        type="date"
        placeholder="Date of Birth"
        onChange={(e) => setDateOfBirth(e.target.value)}
        value={dateOfBirth}
        required
      />
      <LabeledInput
        label="Aadhar Card Number"
        name="aadharCardNumber"
        type="text"
        placeholder="Aadhar Card Number"
        onChange={(e) => setAadharCardNumber(e.target.value)}
        value={aadharCardNumber}
        required
      />
      <LabeledInput
        label="WhatsApp Number"
        name="whatsappNumber"
        type="tel"
        placeholder="WhatsApp Number"
        onChange={(e) => setWhatsappNumber(e.target.value)}
        value={whatsappNumber}
        required
      />

      <div>
        <label htmlFor="tshirtSize" className="block text-gray-700">
          T-shirt Size
        </label>
        <select
          name="tshirtSize"
          id="tshirtSize"
          onChange={(e) => setTshirtSize(e.target.value)}
          value={tshirtSize}
          className="w-full px-4 py-2 border rounded-lg focus:outline-none"
          required
        >
          <option value="">Select T-shirt Size</option>
          <option value="S">Small</option>
          <option value="M">Medium</option>
          <option value="L">Large</option>
          <option value="XL">Extra Large</option>
          <option value="XXL">XXL</option>
        </select>
      </div>

      <LabeledInput
        label="City"
        name="city"
        type="text"
        placeholder="City"
        onChange={(e) => setCity(e.target.value)}
        value={city}
        required
      />
      <LabeledInput
        label="State"
        name="state"
        type="text"
        placeholder="State"
        onChange={(e) => setState(e.target.value)}
        value={state}
        required
      />
      <LabeledInput
        label="Visit Day (1 to 60)"
        name="visitDay"
        type="number"
        placeholder="Visit Day (1 to 60)"
        onChange={(e) => setVisitDay(e.target.value)}
        value={visitDay}
        required
      />
      <LabeledInput
        label="Emergency Contact Number"
        name="emergencyContactNumber"
        type="tel"
        placeholder="Emergency Contact Number"
        onChange={(e) => setEmergencyContactNumber(e.target.value)}
        value={emergencyContactNumber}
        required
      />
    </>
  );
};

export default FormFields;
