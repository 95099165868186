import React from 'react';
import Layout from '../components/Layout';  // Import Layout component
import RegisterForm from '../components/RegistrationForm';  // Import RegisterForm component

const Register = () => {
  return (
    <Layout>
      < RegisterForm /> 
    </Layout>
  );
};

export default Register;
