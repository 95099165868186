import React from "react";

const LabeledInput = ({ label, name, type, placeholder, onChange, required, disabled }) => {
  return (
    <div>
      <label htmlFor={name} className="block text-gray-700">
        {label}
      </label>
      <input
        type={type}
        name={name}
        id={name}
        placeholder={placeholder}
        onChange={onChange}
        className="w-full px-4 py-2 border rounded-lg focus:outline-none"
        required={required}
        disabled={disabled}
      />
    </div>
  );
};

export default LabeledInput;
