// src/services/apiService.js

import axios from 'axios';


const isLocalhost = window.location.hostname === 'localhost';

const API_BASE_URL = isLocalhost
  ? 'http://localhost:3001'
  : 'https://api.hindujodoyatra.org';

  
// Function to request OTP
export const requestOtp = async (email) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/otp/request-otp`, { email });
    return response.data; // Return the response data
  } catch (error) {
    throw error;
  }
};

// Function to verify OTP
export const verifyOtp = async (email, otp) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/otp/verify-otp`, { email, otp });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function to submit the registration form
export const submitRegistration = async (registrationPayload) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/register`, registrationPayload, {
      headers: {
        "Content-Type": "application/json"
      }
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};
